import React from 'react';
import WebTab from './_code-web';
import ReactNativeTab from './_code-reactnative';
import AndroidTab from './_code-android';
import AppleTab from './_code-apple';
import OtherTab from './_code-other';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../components';
import pageHeroData from '../../../data/pages/guidelines.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Iconography"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Iconography" />

      <PlatformTabs
        platforms={{
          web: <WebTab />,
          reactnative: <ReactNativeTab />,
          android: <AndroidTab />,
          apple: <AppleTab />,
          other: <OtherTab />,
        }}
      />
    </PageWithSubNav>
  );
};

export default IndexPage;
